import ax from '../../helpers/restful/service';

export const state = {
    currentUser: window.localStorage.getItem('auth.user'),
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue;
        saveState('auth.user', newValue);
    },
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    },
    getCurrentUser(state){
        return state.currentUser;
    }
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('validate')
    },
    
    // Logs in the current user.
    // eslint-disable-next-line no-unused-vars
    async logIn({ commit, dispatch, getters }, { username, password } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        const resp = await ax.post("/login", { username: username, password: password}, {}).catch((e) => {
            console.error("error:", e);
            if(e.status >= 400 && e.status <= 409) return e;
            return false;
        });
        if(resp == null || resp == undefined || !resp) return false;
        
        if(resp.status == 409) return resp;

        if(resp.data == null || resp.data == undefined) return false;
        
        if(resp.status != 200) return false;

        commit('SET_CURRENT_USER', resp.data);
        return resp.data;

    },

    // Logs out the current user.
    // eslint-disable-next-line no-unused-vars
    logOut({ commit }) {
        commit('SET_CURRENT_USER', null)
        return true;
    },

    // register the user
    // eslint-disable-next-line no-unused-vars
    async forgotPassword({ commit }, { username, phone } = {}) {
        console.log("username:", username);
        console.log("phone:", phone);
        console.log("commit:", commit);
        const resp = await ax.post("/forgot-password", { username: username, phone: phone}, {}).catch((e) => {
            console.error("error:", e);
            if(e.status >= 400 && e.status <= 409) return e;
            return false;
        });

        console.log("resp:", resp);

        if(resp == null || resp == undefined || !resp) return false;
        
        if(resp.status == 409) return resp;

        if(resp.data == null || resp.data == undefined) return false;
        
        if(resp.status != 200) return false;

        return true;
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    async validate({ commit, state }) {
        if (!state.currentUser) return Promise.resolve(null)
        // const user = getFirebaseBackend().getAuthenticatedUser();
        const resp = await ax.post("/login", { username: state.currentUser.username, password: state.currentUser.password}, {});
        
        if(resp == null || resp == undefined) return false;

        if(resp.data == null || resp.data == undefined) return false;

        commit('SET_CURRENT_USER', resp.data);
        return resp.data;
    },
}

// ===
// Private helpers
// ===

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state));
}

import Vue from 'vue';
import VueRouter from 'vue-router';

import VueMeta from 'vue-meta';
import store from '@/state/store';

import routes from './routes';

Vue.use(VueRouter)
Vue.use(VueMeta, {
    keyName: 'page',
})

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                x: 0,
                y: 0
            }
        }
    },
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
    console.log("store:", store);
    console.log("routeTo.path:", routeTo.path);
    console.log("routeFrom.path:", routeFrom.path);
    const origin_route = localStorage.getItem('auth.originRoute');

    const publicPages = ['/login', '/admin-summary-registed-list'];
    const is_authpage = !publicPages.includes(routeTo.path);
    const loggeduser = JSON.parse(localStorage.getItem('auth.user'));

    console.log("is_authpage : ", is_authpage);
    console.log("origin_route : ", origin_route);
    console.log("!loggeduser : ", !loggeduser);
    console.log("is_authpage && loggeduser == null : ", is_authpage && loggeduser === null);
    
    if (publicPages.includes(routeTo.path)) {
        if(loggeduser) {
            if(routeTo.path == '/login') return next('/main');
        }
        return next();
    }

    if (loggeduser !== null) {
        console.log("loggeduser:", loggeduser);
        if (origin_route != "" && routeTo.path === origin_route) {
            localStorage.setItem('auth.originRoute', '');
            return next();
        }
        if (origin_route == null){
            localStorage.setItem('auth.originRoute', '');
            return next('/main');
        }
    }

    if (is_authpage && loggeduser === null) {
        localStorage.setItem('auth.originRoute', "/" + routeTo.path.split("/")[1] + "/" + routeTo.path.split("/")[2]);
        return next('/login');
    }
    return next();
});

// router.beforeResolve(async (routeTo, routeFrom, next) => {
//     // Create a `beforeResolve` hook, which fires whenever
//     // `beforeRouteEnter` and `beforeRouteUpdate` would. This
//     // allows us to ensure data is fetched even when params change,
//     // but the resolved route does not. We put it in `meta` to
//     // indicate that it's a hook we created, rather than part of
//     // Vue Router (yet?).
//     try {
//         // For each matched route...
//         for (const route of routeTo.matched) {
//             await new Promise((resolve, reject) => {
//                 // If a `beforeResolve` hook is defined, call it with
//                 // the same arguments as the `beforeEnter` hook.
//                 if (route.meta && route.meta.beforeResolve) {
//                     route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
//                         // If the user chose to redirect...
//                         if (args.length) {
//                             // If redirecting to the same route we're coming from...
//                             // Complete the redirect.
//                             next(...args)
//                             reject(new Error('Redirected'))
//                         } else {
//                             resolve()
//                         }
//                     })
//                 } else {
//                     // Otherwise, continue resolving the route.
//                     resolve()
//                 }
//             })
//         }
//         // If a `beforeResolve` hook chose to redirect, just return.
//     } catch (error) {
//         return
//     }

//     // If we reach this point, continue resolving the route.
//     next()
// })

export default router